import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import AnimatedCountUpDecimal from "../../components/AnimatedCountUpDecimal"
import BannerBlepharo from "../../components/choc/BannerBlepharo"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import QuadTeaser from "../../components/choc/QuadTeaser"
import QuadTeaserFacelift from "../../components/choc/QuadTeaserFacelift"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElement"
import ImgTextModule from "../../components/ImgTextModule"
import LayoutA from "../../components/layout/LayoutA"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
        title={"Ästhetik: High Lamellar SMAS Lift in Wilhelmshaven | VeoMed"}
        description={"Straffung des Gesichts und des Halsbereiches mit Entfernung / Hautstraffung"}
    >
      <ImgTextModule
        src={"235354.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="85vh"
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "4xl", lg: "9xl" }}
            >
              Facelift:
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "6xl", lg: "6xl" }}
            >
              High Lamellar SMAS Lift
            </Heading>
          </FadeInAnimation>

          <Flex mt={0}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.5}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink
                isExternal={true}
                href="https://www.doctolib.de/praxis/wilhelmshaven/jade-klinik-dr-jann-voltmann-kollegen/booking/motive-categories?placeId=practice-349684&specialityId=1308&telehealth=false"
              >
                <Button mt={4} variant="ghost" color="white">
                  Termine online
                </Button>
              </RouterLink>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={0.74}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} link={"/kontakt/"}>
                <Button
                  ml={4}
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  _hover={{
                    backgroundColor: "brand.violet.primary",
                    color: "white",
                  }}
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Flex>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaser
        py={24}
        headline="Was ist ein High Lamellar SMAS Lift ?"
        subheadline="Straffung des Gesichts und des Halsbereiches mit Entfernung / Hautstraffung 
        "
      />
      <Container
        variant="layoutContainer"
        mt={0.5}
        py={12}
        mb={0.5}
        px={{ base: 4, md: 8, lg: 32 }}
      >
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Wann?"
          text1="Bei starker Faltenbildung."
          text2="
          Bei ausgeprägter Faltenbildung – ab 2-3 mm Faltentiefe im Gesicht
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was?"
          text1="Was wird gemacht"
          text2="
          Die Muskel-Bindegewebsschicht unter der Haut von Gesicht und Hals (SMAS Abkürzung für Superfizielles musculoaponeurotisches System) wird durch eine spezielle Operationstechnik neu ausgerichtet.

          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Wie?"
          text1="Wir verstecken den Schnitt vor- sowie hinter dem Ohr in einer Hautlinie."
          text2="
          Die zu straffende Hauptpartie wird schonend mit dem Wasserstrahl verfahren gelöst. Dabei werden Nerven und Gefäße geschont und die Haut wird anschließend in einem definierten Bereich angehoben und das darunterliegende Schicht (SMAS) gestrafft und neu ausgerichtet.

          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Der Vorteil"
          text1="Langlebig und natürlich"
          text2="
          Der Vorteil des Verfahrens ist eine langanhaltende Wirkung. Ihr Lächeln erscheint weiterhin natürlich, Ihr Gesicht erhält ein erfrischendes und verjüngtes Aussehen. Die Operation bewahrt Ihren individuellen optischen Charakter und verhindert den klassischen »gelifteten« Look.
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Heilung"
          text1="Wie lange dauert es? "
          text2="
          Nach ca. 2 Wochen ist man wieder gesellschaftsfähig.
Das Endergebnis ist erst ca. 6 Monate nach dem Eingriff vollständig entwickelt.
          "
        />
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"32711.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "2xl" }}
              >
                Bereits ab:
              </Text>
            </FadeInAnimation>
            <InViewTrigger threshold={0.1} triggerOnce={false}>
              {inView => (
                <AnimatedCountUpDecimal
                  inView={inView}
                  unit="€"
                  fontSizeUnit="lg"
                  count={6500}
                  fontSize={"6rem"}
                  duration={3}
                  decimal={false}
                  decimalDuration={2}
                  decimalCount={0}
                  unitSpacing={3}
                  numberColor={"white"}
                  textColor={"white"}
                  cellHeight={"6rem"}
                  letterSpacing={"-0.2rem"}
                ></AnimatedCountUpDecimal>
              )}
            </InViewTrigger>
            <Flex
              flexWrap="wrap"
              flexDir={{ base: "column", md: "column", lg: "row" }}
              mt={0}
            >
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  <br />
                  Wir beraten Sie gern im Gespräch.
                </Heading>
              </MaskSlidInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://www.doctolib.de/praxis/wilhelmshaven/jade-klinik-dr-jann-voltmann-kollegen/booking/motive-categories?placeId=practice-349684&specialityId=1308&telehealth=false"
                >
                  <Button mt={4} variant="ghost" color="white">
                    Termine online
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.74}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/kontakt/"}>
                  <Button
                    ml={{ base: 0, md: 4 }}
                    mt={4}
                    variant="solid"
                    color="brand.violet.primary"
                    _hover={{
                      backgroundColor: "brand.violet.primary",
                      color: "white",
                    }}
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <QuadTeaserFacelift />
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"35336.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="76vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Wie geht es weiter?
              </Heading>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "3xl" }}
              >
                Wir stehen gern zu einem Beratungs- <br />
                und Informationsgespräch bereit.
              </Heading>
              <Flex mt={0}>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink
                    isExternal={true}
                    href="https://www.doctolib.de/praxis/wilhelmshaven/jade-klinik-dr-jann-voltmann-kollegen/booking/motive-categories?placeId=practice-349684&specialityId=1308&telehealth=false"
                  >
                    <Button mt={4} variant="ghost" color="white">
                      Termine online
                    </Button>
                  </RouterLink>
                </FadeInAnimation>

                <FadeInAnimation
                  threshold={0.4}
                  delay={0.74}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} link={"/kontakt/"}>
                    <Button
                      ml={4}
                      mt={4}
                      variant="solid"
                      color="brand.violet.primary"
                      _hover={{
                        backgroundColor: "brand.violet.primary",
                        color: "white",
                      }}
                    >
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <LogoHeadlineTeaser
        my={12}
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />{" "}
      <BannerHyaloron />
      <BannerBlepharo />
      <QuadTeaser />
      <Container variant="layoutContainer" mt={0.5} py={4} mb={0.5}>
        {" "}
        <BannerVeoJadeklinik />
      </Container>
    </LayoutA>
  )
}

export default Blank
